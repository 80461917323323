import { Entity } from '@backstage/catalog-model';

export const KOBS_APPLICATION_ANNOTATION = 'kobs.io/application';

export const isKobsAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[KOBS_APPLICATION_ANNOTATION]);

export const useApplicationKey = (entity: Entity) => {
  return entity?.metadata.annotations?.[KOBS_APPLICATION_ANNOTATION] ?? '';
};
