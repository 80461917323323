import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

import { DiscoveryApi } from '@backstage/core-plugin-api';

type Options = {
  discoveryApi: DiscoveryApi;
};

export class TechRadarClient implements TechRadarApi {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
  }

  private async getUrl() {
    return await this.discoveryApi.getBaseUrl('proxy');
  }

  // Call to internal API
  async load(): Promise<TechRadarLoaderResponse> {
    // Fetch through Backend-Proxy
    const backendUrl = await this.getUrl();
    const data = await fetch(`${backendUrl}/tech-radar/`).then(response =>
      response.json(),
    );

    // ADOPT
    data.rings[0].color = '#5ba301';
    // TRIAL
    data.rings[1].color = '#019eb0';
    // ASSESS
    data.rings[2].color = '#fcc12d';
    // HOLD
    data.rings[3].color = '#fa5748';

    return data;
  }
}
