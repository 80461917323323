/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Category';
import ExtensionIcon from '@material-ui/icons/Extension';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import {
  ChatIcon,
  GroupIcon,
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
} from '@backstage/core-components';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';

import ComponentIcon from '@material-ui/icons/Memory';
import ResourceIcon from '@material-ui/icons/Work';
import UserIcon from '@material-ui/icons/Person';
import { SidebarSearchModal } from '@backstage/plugin-search';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      {/* Global nav, not org-specific */}
      <SidebarItem icon={HomeIcon} to="/" text="Home" />
      <MyGroupsSidebarItem
        singularTitle="My Team"
        pluralTitle="My Groups"
        icon={GroupIcon}
      />
      <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog">
        <SidebarSubmenu title="Catalog">
          <SidebarSubmenuItem
            title="Components"
            to="catalog?filters[kind]=component"
            icon={ComponentIcon}
          />
          <SidebarSubmenuItem
            title="Resources"
            to="catalog?filters[kind]=resource"
            icon={ResourceIcon}
          />
          <SidebarDivider />
          <SidebarSubmenuItem
            title="Groups"
            to="catalog?filters[kind]=group"
            icon={GroupIcon}
          />
          <SidebarSubmenuItem
            title="Users"
            to="catalog?filters[kind]=user"
            icon={UserIcon}
          />
        </SidebarSubmenu>
      </SidebarItem>
      <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
      <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
      <SidebarDivider />
      <SidebarItem icon={ChatIcon} to="watson" text="Watson" />
      {/* <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />*/}
      {/* End global nav */}
      {/* <SidebarDivider />*/}
      {/* <SidebarItem icon={LayersIcon} to="explore" text="Explore" />*/}
      {/* <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" /> */}
      {/* <SidebarDivider />
      <SidebarItem
        icon={AssessmentIcon}
        to="engineering-excellence-score"
        text="EES"
      /> */}
      <SidebarSpace />
      <SidebarDivider />
      <SidebarSettings />
    </Sidebar>
    {children}
  </SidebarPage>
);
