import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const engineeringExcellenceScorePlugin = createPlugin({
  id: 'engineering-excellence-score',
  routes: {
    root: rootRouteRef,
  },
});

export const EngineeringExcellenceScorePage =
  engineeringExcellenceScorePlugin.provide(
    createRoutableExtension({
      name: 'EngineeringExcellenceScorePage',
      component: () => import('./components/Dashboard').then(m => m.Dashboard),
      mountPoint: rootRouteRef,
    }),
  );
