import React, { FC, ReactNode } from 'react';
import { Link } from '@backstage/core-components';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

interface Props {
  primaryText: string;
  secondaryText: string;
  link: string;
  icon: ReactNode;
}

const LinkListItem: FC<Props> = ({
  primaryText,
  secondaryText,
  link,
  icon,
}) => {
  return (
    <ListItem>
      <ListItemIcon>{icon}</ListItemIcon>
      <Link to={link}>
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          primaryTypographyProps={{
            style: {
              whiteSpace: 'normal',
            },
          }}
          secondaryTypographyProps={{
            style: {
              whiteSpace: 'normal',
            },
          }}
        />
      </Link>
    </ListItem>
  );
};

export default LinkListItem;
