import { InfoCard } from '@backstage/core-components';
import { WelcomeTitle } from '@backstage/plugin-home';
import { Typography } from '@material-ui/core';
import React from 'react';

export const WelcomeCard = () => {
  return (
    <InfoCard title={<WelcomeTitle />}>
      <Typography paragraph>
        Here you can explore the service landscape from Staffbase. You can see
        the ownership for components and can discover its documentation.
      </Typography>
    </InfoCard>
  );
};
