import React, { useState } from 'react';
import {
  InfoCard,
  InfoCardVariants,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import { Button, Collapse, List } from '@material-ui/core';
import { useOwnedEntities } from './useOwnedEntities';
import { OwnedItem } from './OwnedItem';

export const OwnedComponentsCard = ({
  title,
  variant,
  numOwnedOpen = 5,
}: {
  title?: string;
  variant?: InfoCardVariants;
  numOwnedOpen?: number;
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { error, loading, entities } = useOwnedEntities();
  const label = collapsed ? 'View More' : 'View Less';
  const actions = (
    <Button onClick={() => setCollapsed(!collapsed)}>{label}</Button>
  );

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  if (!entities || entities.length === 0) {
    return null;
  }

  return (
    <InfoCard title={title} variant={variant} noPadding actions={actions}>
      <List dense>
        {entities.slice(0, numOwnedOpen).map((entity, index) => (
          <OwnedItem key={index} entity={entity} />
        ))}
        {entities.length > numOwnedOpen && (
          <Collapse in={!collapsed}>
            {entities
              ?.slice(numOwnedOpen, entities.length)
              .map((entity, index) => (
                <OwnedItem key={index} entity={entity} />
              ))}
          </Collapse>
        )}
      </List>
    </InfoCard>
  );
};
