import {
  ScmAuth,
  scmAuthApiRef,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  githubAuthApiRef,
} from '@backstage/core-plugin-api';
import { TechRadarClient } from './lib/TechRadarClient';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),

  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ githubAuthApi }) =>
      ScmAuth.merge(ScmAuth.forGithub(githubAuthApi)),
  }),

  createApiFactory({
    api: techRadarApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
    },
    factory: ({ discoveryApi }) => {
      return new TechRadarClient({
        discoveryApi,
      });
    },
  }),
];
