import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  PageTheme,
  palettes,
} from '@backstage/theme';
import { yellow } from '@material-ui/core/colors';

const emptyShape = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E")`;

const pageTheme: Record<string, PageTheme> = {
  home: genPageTheme({ colors: ['#00A4FD', '#00A4FD'], shape: emptyShape }),
  apis: genPageTheme({ colors: ['#00A4FD', '#00A4FD'], shape: emptyShape }),
  documentation: genPageTheme({
    colors: ['#00A4FD', '#00A4FD'],
    shape: emptyShape,
  }),
  other: genPageTheme({ colors: ['#00A4FD', '#00A4FD'], shape: emptyShape }),
};

export const staffbaseLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      background: {
        default: '#F9F9F9',
      },
      status: {
        ok: '#1DB954',
        warning: '#FF9800',
        error: '#E22134',
        running: '#2E77D0',
        pending: '#FFED51',
        aborted: '#757575',
      },
      bursts: {
        fontColor: '#FEFEFE',
        slackChannelText: '#ddd',
        backgroundColor: {
          default: '#7C3699',
        },
        gradient: {
          linear: 'linear-gradient(-137deg, #00A4FD 0%, #2E77D0 100%)',
        },
      },
      primary: {
        main: '#2E77D0',
      },
      banner: {
        info: '#2E77D0',
        error: '#E22134',
        text: '#FFFFFF',
        link: '#000000',
      },
      border: '#E6E6E6',
      textContrast: '#000000',
      textVerySubtle: '#DDD',
      textSubtle: '#6E6E6E',
      highlight: '#FFFBCC',
      errorBackground: '#FFEBEE',
      warningBackground: '#F59B23',
      infoBackground: '#ebf5ff',
      errorText: '#CA001B',
      infoText: '#004e8a',
      warningText: '#000000',
      linkHover: '#2196F3',
      link: '#0A6EBE',
      gold: yellow.A700,
      navigation: {
        background: '#171717',
        indicator: '#FFDC00',
        color: '#b5b5b5',
        selectedColor: '#FFDC00',
      },
      pinSidebarButton: {
        icon: '#181818',
        background: '#BDBDBD',
      },
      tabbar: {
        indicator: '#9BF0E1',
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme,
});

export const staffbaseDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      background: {
        default: '#333333',
      },
      status: {
        ok: '#71CF88',
        warning: '#FFB84D',
        error: '#F84C55',
        running: '#3488E3',
        pending: '#FEF071',
        aborted: '#9E9E9E',
      },
      bursts: {
        fontColor: '#FEFEFE',
        slackChannelText: '#ddd',
        backgroundColor: {
          default: '#7C3699',
        },
        gradient: {
          linear: 'linear-gradient(-137deg, #00A4FD 0%, #2E77D0 100%)',
        },
      },
      primary: {
        main: '#9CC9FF',
        dark: '#82BAFD',
      },
      secondary: {
        main: '#FF88B2',
      },
      banner: {
        info: '#2E77D0',
        error: '#E22134',
        text: '#FFFFFF',
        link: '#000000',
      },
      border: '#E6E6E6',
      textContrast: '#FFFFFF',
      textVerySubtle: '#727272',
      textSubtle: '#CCCCCC',
      highlight: '#FFFBCC',
      errorBackground: '#FFEBEE',
      warningBackground: '#F59B23',
      infoBackground: '#ebf5ff',
      errorText: '#CA001B',
      infoText: '#004e8a',
      warningText: '#000000',
      linkHover: '#82BAFD',
      link: '#9CC9FF',
      gold: yellow.A700,
      navigation: {
        background: '#424242',
        indicator: '#FFDC00',
        color: '#b5b5b5',
        selectedColor: '#FFDC00',
      },
      pinSidebarButton: {
        icon: '#404040',
        background: '#BDBDBD',
      },
      tabbar: {
        indicator: '#9BF0E1',
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme,
});
