import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  entityRouteParams,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PublicIcon from '@material-ui/icons/Public';

export const OwnedItem = ({ entity }: { entity: Entity }) => {
  const catalogEntityRoute = useRouteRef(entityRouteRef);

  return (
    <>
      <ListItem>
        <ListItemIcon>
          {entity.spec?.type === 'API' ? (
            <PublicIcon />
          ) : (
            <AccountBalanceIcon />
          )}
        </ListItemIcon>
        <Link to={catalogEntityRoute(entityRouteParams(entity))}>
          <ListItemText
            primary={entity.metadata.title ?? entity.metadata.name}
            secondary={entity.metadata.description}
            primaryTypographyProps={{
              style: {
                whiteSpace: 'normal',
              },
            }}
            secondaryTypographyProps={{
              style: {
                whiteSpace: 'normal',
              },
            }}
          />
        </Link>
      </ListItem>
    </>
  );
};
