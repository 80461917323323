import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const kobsPlugin = createPlugin({
  id: 'kobs',
  routes: {
    root: rootRouteRef,
  },
});

export const EntityKobsApplicationCard = kobsPlugin.provide(
  createComponentExtension({
    name: 'EntityKobsApplicationCard',
    component: {
      lazy: () =>
        import('./components/KobsApplicationCard').then(
          m => m.KobsApplicationCard,
        ),
    },
  }),
);
