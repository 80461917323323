import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const watsonPlugin = createPlugin({
  id: 'watson',
  routes: {
    root: rootRouteRef,
  },
});

export const WatsonSearchPage = watsonPlugin.provide(
  createRoutableExtension({
    name: 'WatsonSearchPage',
    component: () =>
      import('./components/WatsonSearchPage').then(m => m.WatsonSearchPage),
    mountPoint: rootRouteRef,
  }),
);
