import { Entity } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';

export function useOwnedEntities(): {
  entities: Entity[] | undefined;
  loading: boolean;
  error?: Error;
} {
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const kinds = ['Component', 'API'];

  const {
    loading,
    error,
    value: entities,
  } = useAsync(async () => {
    const { ownershipEntityRefs } = await identityApi.getBackstageIdentity();
    const ownedEntitiesList = await catalogApi.getEntities({
      filter: [
        {
          kind: kinds,
          'relations.ownedBy': ownershipEntityRefs,
        },
      ],
      fields: ['kind', 'metadata.name', 'metadata.description', 'spec.type'],
    });

    return ownedEntitiesList.items.sort((a, b) => {
      if (a.metadata.name < b.metadata.name) {
        return -1;
      }
      if (a.metadata.name > b.metadata.name) {
        return 1;
      }
      return 0;
    });
  }, [catalogApi]);

  return {
    entities,
    loading,
    error,
  };
}
