import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { EngineeringEfficiencyScoresCard } from './EngineeringEfficiencyScoresCard';
import { InterestingReadsCard } from './InterestingReadsCard';
import { OwnedComponentsCard } from './OwnedComponentsCard';
import { QuicklinksCard } from './QuicklinksCard';
import { WelcomeCard } from './WelcomeCard';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const HomePage = () => {
  const classes = useStyles();

  return (
    <Container classes={{ root: classes.root }}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 600: 1, 960: 2 }}>
        <Masonry gutter="1em" columnsCount={2}>
          <WelcomeCard />
          <QuicklinksCard />
          <EngineeringEfficiencyScoresCard />
          <OwnedComponentsCard title="Your Owned Components" />
          <InterestingReadsCard />
        </Masonry>
      </ResponsiveMasonry>
    </Container>
  );
};
