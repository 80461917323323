import {
  DomainExplorerContent,
  ExploreLayout,
  GroupsExplorerContent,
} from '@backstage-community/plugin-explore';
import React from 'react';

export const ExplorePage = () => (
  <ExploreLayout
    title="Explore the Staffbase ecosystem"
    subtitle="Discover our ecosystem"
  >
    <ExploreLayout.Route path="domains" title="Domains">
      <DomainExplorerContent />
    </ExploreLayout.Route>
    <ExploreLayout.Route path="groups" title="Groups">
      <GroupsExplorerContent />
    </ExploreLayout.Route>
  </ExploreLayout>
);

export const explorePage = <ExplorePage />;
