import { InfoCard } from '@backstage/core-components';
import { List } from '@material-ui/core';
import React from 'react';
import LinkListItem from '../LinkListItem';
import LockIcon from '@material-ui/icons/Lock';
import ScoreIcon from '@material-ui/icons/Score';

export const EngineeringEfficiencyScoresCard = () => {
  return (
    <InfoCard title="Explore Engineering Efficiency Scores" noPadding>
      <List dense>
        <LinkListItem
          primaryText="Engineering Efficiency Scores"
          secondaryText="Summary of the Engineering Efficiency Scores"
          link="https://lookerstudio.google.com/reporting/c0dc30fe-049f-4a5b-9e9b-bede26e6bd1d"
          icon={<ScoreIcon />}
        />
        <LinkListItem
          primaryText="Application Security Report"
          secondaryText="Discover vulnerabilities for your services"
          link="https://lookerstudio.google.com/reporting/c0dc30fe-049f-4a5b-9e9b-bede26e6bd1d/page/p_g73zxkerbd"
          icon={<LockIcon />}
        />
      </List>
    </InfoCard>
  );
};
